import { render, staticRenderFns } from "./footballgameplan.vue?vue&type=template&id=71fefbf0&scoped=true&"
import script from "./footballgameplan.vue?vue&type=script&lang=js&"
export * from "./footballgameplan.vue?vue&type=script&lang=js&"
import style0 from "./footballgameplan.vue?vue&type=style&index=0&id=71fefbf0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71fefbf0",
  null
  
)

export default component.exports